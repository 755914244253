import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { FaFacebookSquare } from "react-icons/fa";
import { FaGooglePlusSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

import { FaSquareDribbble } from "react-icons/fa6";

const Footer = () => {


    const sOne = [
        { sname: 'Logo & Branding' },
        { sname: 'Website Development' },
        { sname: 'Mobile App Development' },
        { sname: 'Search Engine Optimization' },
        { sname: 'Social Media Marketing' }
    ]
    const sTwo = [
        { sname: 'Blog' },
        { sname: 'Case Study' },
        { sname: 'Portfolio' },
        { sname: 'Team pages' }
    ]
    const sThree = [
        { sname: 'Contact' },
        { sname: 'Privacy Policy' },
        { sname: 'Terms of Use' }
    ]



    return (
        
        <div className='footer'>
            <div className="container">
                <div className="row">
                <div className="col-lg-6 mx-auto">
                        <div className="social-links">
                            <span><FaFacebookSquare/></span>
                            <span><FaGooglePlusSquare/></span>
                            <span><FaInstagramSquare/></span>
                            <span><FaSquareDribbble/></span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-lg-5">
                        <div className="footer-left">
                            <h2>Get in touch!</h2>
                            <p>We are Pune’s Best Digital Marketing <br /> Website Designing & Mobile App <br />Development Company</p>
                            <div className='d-flex justify-content-between align-items-center'>
                                <input type="email" className='form-control' placeholder='Email' />
                                <button>Subscribe <FaLocationArrow /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="imp-links">
                            <h5>Services</h5>
                            <ul>
                                {
                                    sOne.map((service, Index) => (
                                        <li key={Index}>
                                            <a href="#">{service.sname}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-lg-2">
                        <div className="imp-links">
                            <h5>Resourses</h5>
                            <ul>
                                {
                                    sTwo.map((service, Index) => (
                                        <li key={Index}>
                                            <a href="#">{service.sname}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-lg-2">
                        <div className="imp-links">
                            <h5>Support</h5>
                            <ul>
                                {
                                    sThree.map((service, Index) => (
                                        <li key={Index}>
                                            <a href="#">{service.sname}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-lg-12 copy">
                        <span>Copyright © 2024 Pune Digital Agency All rights reserved</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
