import React from 'react';
import Hero from '../Hero';
import Aboutus from '../Aboutus';
import Services from '../Services';
import Testimonilas from '../Testimonilas';
import { FaLocationArrow } from "react-icons/fa";
import Inquiry from '../Inquiry';
import { Link } from 'react-router-dom';
import Clientslider from '../Clientslider';
import BlogSection from '../BlogSection';
const Home = () => {
  return (
    <div>
      <Hero />
      <Services />
      <Viewmore/>
      <Aboutus />
      <Testimonilas />
      <Clientslider/>
      <Inquiry />
      <BlogSection/>
    </div>
  )
}

export default Home;

const Viewmore = () => {
  return (
    <div className="container">
      <div className="row">
      <div className="col-lg-3 mx-auto">
        <div className="view-more-btn">
          <Link to="/services">View More <FaLocationArrow/></Link>          
        </div>
      </div>
    </div>
    </div>
  )
}