import React from "react";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";

function Hero(){
    return(
        <div className="hero">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 my-auto">
                        <span className="spans">ORBIT TECHNOLOGY</span>
                        <h1>We are <span>Digital Marketing Agency.</span></h1> 
                        <h2>we are provide all Type of digital Marketing Services</h2>
                        <p>We offer a full range of digital marketing services designed to enhance your online presence and drive business growth. Our expertise includes search engine optimization (SEO) to improve your website's visibility, social media management to engage and grow your audience, and content marketing to provide valuable, relevant content to your customers. </p>
                            <p>We also specialize in pay-per-click (PPC) advertising to generate immediate traffic, email marketing to nurture leads, and comprehensive analytics to track and optimize your campaigns. Our team of experienced professionals is dedicated to creating tailored strategies that meet your unique business goals and deliver measurable results.</p>
                        <Link to="/contact"><button className="btn"> Contact Us <FaLocationArrow/></button></Link>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div>
                            <img src="./images/pune-digital-agency.jpg" className="w-100" title="Pune Digital Agency About US" alt="Pune Digital Agency About US" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero;