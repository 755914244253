import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Aboutus() {
  return (
    <div className='aboutus'>
        <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div>
                            <img src="./images/pune-digital-agency-about-us.png" className="w-100" title="Pune Digital Agency About US" alt="Pune Digital Agency About US" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 my-auto">
                        <span className="spans">About Us</span>
                        <h2>Speeding up digital transformation is crucial to endure and prosper.</h2>
                        <p>We work alongside our clients as one team with a shared ambition to achieve extraordinary results, outperform the competition and redefine industries.</p>
                        <Link to="/about"><button className="btn"> read more <FaLocationArrow/></button></Link>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Aboutus
