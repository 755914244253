import React from 'react';
import { Link } from 'react-router-dom';


function Header() {
  const navLinks = [
    { linkName: "Home", linkPath: "/" },
    { linkName: "About Us", linkPath: "/about" },
    { linkName: "Services", linkPath: "/services" },
    { linkName: "Contact Us", linkPath: "/contact" },
    { linkName: "Blog", linkPath: "/blog" }
  ];

  return (
    <header>
      <div className='container'>
        <div className='row'>
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src="../images/logo.png" alt="" />
              </Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {navLinks.map((link, index) => (
                    <li className="nav-item" key={index}>
                      <Link className="nav-link active" aria-current="page" to={link.linkPath}>{link.linkName}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
